import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    margin-top: 60px;
    background: white;
    z-index: 1;
    color: var(--color-anchor-dark);

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  `,
  link: css`
    font-size: 5vw;
    color: var(--color-anchor-dark) !important;
    margin: 0 0 1.5rem 0;

    text-align: center;
    height: 14vw;
  `,
};
