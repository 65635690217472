import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    display: flex;
    position: relative;
  `,
  infoDialog: (
    absolute: boolean,
    isMobile: boolean,
    minHeight?: number,
    standalone?: boolean
  ) => css`
    position: ${absolute ? "absolute" : "inherit"};
    width: 100%;
    height: ${isMobile ? "calc(100vh - 3rem)" : "100%"};
    ${isMobile
      ? "min-height:70vh;"
      : minHeight
      ? `min-height: ${minHeight}px;`
      : ""};
    background: rgba(var(--main-bg-color-val), 0.8);
    z-index: 100;
    display: flex;

    .__sub {
      display: flex;
      ${isMobile ? "flex-direction: column;" : ""}
      ${isMobile ? "align-items: flex-start;" : ""}
      h4 {
        flex: 1;
        ${isMobile ? "text-align:justify;" : ""}
      }
      margin-bottom: 1rem;

      font-family: "times new roman";
    }

    iframe {
      border: none;
      /* flex: 1; */
    }
    h2 {
      font-weight: 600;
      margin-bottom: -0.5rem;
    }
    h4 {
      font-weight: 400;
      padding-right: 2rem;
    }
    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: ${standalone ? "0" : "10px"};
      padding: ${isMobile ? "0" : "1rem 2rem 2rem 2rem"};
      background: rgba(255, 255, 255, 0.9);
      flex: 1;

      .__close {
        transition: all ease 0.3s;
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 30px;
        color: var(--color-light-gray);
        cursor: pointer;
        :hover {
          transform: scale(1.2);
        }
      }
    }
  `,
  mobileCloseButton: css`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    > * {
      flex: 1;
      min-height: 3rem;
    }
  `,
  spotifyIframe: (
    category: "Album" | "Playlist" | "Track",
    hasYoutube: boolean
  ) => css`
    height: ${category === "Track" ? "80px" : "initial"};
    flex: ${category === "Track" ? "initial" : "1"};
    margin-top: ${hasYoutube ? "1.5rem" : "0"};
  `,
};
