import React from "react";
import { styles } from "./styles";
import { IElementType } from "./interfaces";

//PROPS
interface ITypographyProps {
  children: React.ReactElement;
  bold?: boolean;
  weight?: "100" | "200" | "300" | "400" | "600";
  light?: boolean;
  type?: IElementType;
  center?: boolean;
}

//RENDER
export const Typography = (props: ITypographyProps) => {
  const css = styles.wrapper(
    props.weight
      ? Number(props.weight)
      : props.bold === true
      ? 600
      : props.light === true
      ? 300
      : 400,
    props.center ?? false
  );
  if (props.type)
    switch (props.type) {
      case "span":
        return <span css={css}>{props.children}</span>;
      case "h1":
        return <h1 css={[css, styles.h1]}>{props.children}</h1>;
      case "h2":
        return <h2 css={[css, styles.h2]}>{props.children}</h2>;
      case "h3":
        return <h3 css={css}>{props.children}</h3>;
      case "h4":
        return <h4 css={css}>{props.children}</h4>;
      case "h5":
        return <h5 css={css}>{props.children}</h5>;
      case "h6":
        return <h6 css={css}>{props.children}</h6>;
    }
  return <div css={css}>{props.children}</div>;
};
