import { css } from "@emotion/core";

export const styles = {
  wrapper: (isMobile: boolean) => css`
    flex: 1;
    display: flex;
    margin: 0 7vw;
    padding-top: 85px;
    ${isMobile ? "flex-direction: column;" : "flex-direction: row;"}

    > section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      :first-of-type {
        width: ${isMobile ? "100%" : "17vw"};
        min-width: 260px;
        margin-right: 2rem;
      }
      :last-of-type {
        flex: 1;
        margin-top: ${isMobile ? "2rem" : "0"};
      }
    }
  `,
  picture: (photoUrl?: string) => css`
    border-radius: 5px;
    overflow: hidden;
    ${photoUrl && `background: url(${photoUrl}) no-repeat;`}

    background-size: cover;
    margin-bottom: 1rem;
    img {
      width: 100%;
    }
  `,
  profile: css`
    background: rgba(255, 255, 255, 0.5);
    padding: 2rem;
    border-radius: 5px;

    h2 {
      margin: 0 0 1rem 0;
    }

    div {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      text-align: justify;
    }

    i {
      font-weight: 600;
    }
  `,
  pictureInfo: css`
    text-align: left;
    margin-top: -2rem;
    margin-bottom: 0rem;

    a {
      color: var(--color-anchor-dark);
      :hover {
        text-decoration: underline;
      }
    }
  `,
};
