import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { styles } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { IRoute } from "@app/statics/routes/interfaces";
import routes from "@app/statics/routes";
import { Typography } from "../typography";

//PROPS
interface IMobileMenuProps {
  onNavigate: () => void;
}

//RENDER
export const MobileMenu = (props: IMobileMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  //RENDER LINK
  const renderLink = (route: IRoute, key: string) => {
    return (
      <Button
        type="link"
        css={styles.link}
        onClick={() => {
          props.onNavigate();
          navigate(route.path ?? "/");
        }}
      >
        <Typography
          weight={currentPath === route.path ? "400" : "200"}
          key={key}
          type="span"
        >
          <>{route.label}</>
        </Typography>
      </Button>
    );
  };

  return (
    <div css={styles.wrapper}>
      {routes
        .filter((route) => route.direction === "right")
        .map((route, index) => renderLink(route, `entry_mobile_${index}`))}
    </div>
  );
};
