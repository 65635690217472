import React, { useEffect } from "react";
import { styles } from "./styles";
import { Modal } from "antd";
import CreditDetail from "@app/components/creditDetail";
import { useContentContext } from "@app/statics/contentContext";
import { Credit } from "@app/statics/contentContext/contentTypes";
import { modalStyles } from "@app/statics/styles/modal";
import { useWindowDimensions } from "@app/statics/dimensionContext";
import useLocationHash from "@app/statics/hashHook";
import { useLocation } from "react-router-dom";

export const Projects = () => {
  //STATE
  const location = useLocation();
  const [currentCredit, setCurrentCredit] = React.useState<Credit>();

  //CONTEXT
  const content = useContentContext();
  const { isMobile, WindowDimensionViewWidth } = useWindowDimensions();
  const itemsPerRow = isMobile ? 2 : 5;
  const [hash, setHash] = useLocationHash();

  //GHOST CARDS
  const ghostCardCount = Array.from(
    {
      length:
        Math.ceil(content.contentState.allCredits.length / itemsPerRow) *
          itemsPerRow -
        content.contentState.allCredits.length,
    },
    (_, i) => i + 1
  );

  //RENDER CARD
  const Card = (credit: Credit) => {
    return (
      <div
        css={styles.credit(
          WindowDimensionViewWidth(100 / itemsPerRow),
          isMobile
        )}
        onClick={() => setCurrentCredit(credit)}
      >
        <img src={credit.imageUrl} alt={credit.title} />
      </div>
    );
  };

  //CURRENT CREDIT HASH
  useEffect(() => {
    if (!content.contentState.loading) {
      setHash(currentCredit ? `#${currentCredit?.slug}` : "");
    }
    // eslint-disable-next-line
  }, [currentCredit, content.contentState.loading]);
  useEffect(() => {
    if (!content.contentState.loading && hash) {
      setCurrentCredit(
        content.contentState.allCredits.find(
          (c) => c.slug === hash.replace("#", "")
        )
      );
    }
    // eslint-disable-next-line
  }, [content.contentState.loading]);
  useEffect(() => {
    if (hash && hash.length > 0 && currentCredit && currentCredit.title) {
      (window as any).dataLayer &&
        (window as any).dataLayer.push({
          event: "VirtualPageview",
          virtualPageURL: location.pathname + "/" + hash.replace("#", ""),
        });
      (window as any).gtag &&
        (window as any).gtag("event", "credit-detail", {
          credit_title: currentCredit.title,
        });
    }
    // eslint-disable-next-line
  }, [hash]);

  //RENDER
  return (
    <>
      <section css={styles.wrapper(isMobile)}>
        <section css={styles.credits(isMobile)}>
          {content.contentState.allCredits.map((credit, index) => (
            <Card key={`credit_${index}`} {...credit} />
          ))}
          {ghostCardCount.map((index) => (
            <div
              css={styles.credit(
                WindowDimensionViewWidth(100 / itemsPerRow),
                isMobile
              )}
              key={`ghost_${index}`}
            ></div>
          ))}
        </section>
      </section>
      <Modal
        open={currentCredit !== undefined}
        width={WindowDimensionViewWidth(85)}
        onCancel={() => setCurrentCredit(undefined)}
        footer={null}
        css={modalStyles(isMobile)}
        destroyOnClose
      >
        <CreditDetail
          credit={currentCredit}
          standalone
          onClose={() => setCurrentCredit(undefined)}
          minHeight={600}
        />
      </Modal>
    </>
  );
};

export default Projects;
