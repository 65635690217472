import React, { useEffect } from "react";
import { styles } from "./styles";
import { ReactComponent as Logo } from "@app/statics/svgs/logo.svg";
import routes from "@app/statics/routes";
import { useContentContext } from "@app/statics/contentContext";
import { LoadingOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "@app/statics/dimensionContext";

//RENDER
const Splash = () => {
  //STATE
  const [visible, setVisible] = React.useState<boolean>(true);
  const content = useContentContext();

  //CONTEXT
  const { isMobile } = useWindowDimensions();

  //TIMEOUT CHECK
  const waitForLoadingToComplete = async () => {
    while (!content.contentState.loading) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    setVisible(false);
  };

  useEffect(() => {
    setTimeout(waitForLoadingToComplete, 1000);
    // eslint-disable-next-line
  }, []);

  //RENDER
  return visible ? (
    <section
      css={[
        styles.wrapper(isMobile),
        routes.find((m) => m.primaryPage)?.backgroundCss,
      ]}
    >
      <div>
        <Logo />
        <LoadingOutlined />
      </div>
    </section>
  ) : null;
};

export default Splash;
