import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    display: inline-block;
    cursor: pointer;
    span {
      position: relative;
      display: inline-block;
      :first-of-type {
        margin-right: 14px;
      }
    }
    svg {
      margin-top: 2px;
      position: absolute;
      left: -14px;
      top: -14px;
    }
    i {
      font-style: normal;
    }
  `,
};
