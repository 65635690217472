import React from "react";
import { IRoute } from "./interfaces";
import Landing from "@app/pages/landing";
import Contact from "@app/pages/contact";
import { css } from "@emotion/core";
import About from "@app/pages/about";
import Projects from "@app/pages/projects";
import Awards from "@app/pages/awards";
import MusicServices from "@app/pages/music-services";

export const routes: IRoute[] = [
  {
    primaryPage: false,
    path: undefined,
    label: "Music Supervision Services",
    logo: false,
    direction: "left",
    type: "h1",
  },
  {
    primaryPage: true,
    path: "/",
    label: "",
    element: <Landing />,
    logo: true,
    direction: "center",
    backgroundCss: css`
      background: linear-gradient(
        180deg,
        rgba(221, 227, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    `,
  },
  {
    primaryPage: false,
    path: "/",
    label: "Home",
    element: <Landing />,
    logo: false,
    direction: "right",
  },
  {
    primaryPage: false,
    path: "/about",
    label: "About",
    element: <About />,
    logo: false,
    direction: "right",
    backgroundCss: css`
      background-color: var(--main-bg-color);
      background: url(./images/agave_light_grad.jpg) no-repeat fixed;
      background-size: cover;
    `,
    imageCredit: (
      <span>
        Background by{" "}
        <a href="https://unsplash.com/@ceveoh?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Chandra Oh
        </a>{" "}
        on{" "}
        <a href="https://unsplash.com/photos/K3JN34WjG0o?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Unsplash
        </a>
      </span>
    ),
  },
  {
    primaryPage: false,
    path: "/projects",
    label: "Projects",
    element: <Projects />,
    logo: false,
    direction: "right",
    backgroundCss: css`
      background-color: var(--main-white);
    `,
  },
  {
    primaryPage: false,
    path: "/awards",
    label: "Awards",
    element: <Awards />,
    logo: false,
    direction: "right",
    backgroundCss: css`
      background-color: var(--main-bg-color);
      background: url(./images/agave_pink_new.jpg) no-repeat fixed;
      background-size: cover;
    `,
    imageCredit: (
      <span>
        Background by{" "}
        <a href="https://unsplash.com/@_allieastorga_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Allison Astorga
        </a>{" "}
        on{" "}
        <a href="https://unsplash.com/photos/rpaLzaSWZiY?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Unsplash
        </a>
      </span>
    ),
  },
  {
    primaryPage: false,
    path: "/contact",
    label: "Contact",
    element: <Contact />,
    logo: false,
    direction: "right",
    backgroundCss: css`
      background-color: #e6dbcf; // #fffff1;
      background: linear-gradient(
        180deg,
        #d9cfc3 0%,
        rgba(255, 255, 255, 1) 100%
      );
      /* background: linear-gradient(
        180deg,
        rgba(221, 227, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      ); */
    `,
  },
  {
    primaryPage: false,
    path: "/music-services",
    label: "Music Services",
    element: <MusicServices />,
    logo: false,
    direction: "hidden",
    blankPage: true,
    backgroundCss: css`
      background-color: #e6dbcf; // #fffff1;
      background: linear-gradient(
        180deg,
        #d9cfc3 0%,
        rgba(255, 255, 255, 1) 100%
      );
      /* background: linear-gradient(
        180deg,
        rgba(221, 227, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      ); */
    `,
  },
];

export default routes;
