import { createContext, useContext } from "react";
import { IContentContext, IContentState } from "./interfaces";

export const initialContentState: IContentState = {
  loading: true,
  landingCredits: [],
  allCredits: [],
  awards: [],
  team: [],
};

const ContentContext = createContext<IContentContext>({
  contentState: initialContentState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setState: () => {},
});

export default ContentContext;

export const useContentContext = () => useContext(ContentContext);
