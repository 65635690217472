import React, { useRef, useEffect } from "react";
import { styles } from "./styles";
import { Carousel, Modal } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/es/carousel";
import CreditDetail from "../creditDetail";
import { Credit } from "@app/statics/contentContext/contentTypes";
import { modalStyles } from "@app/statics/styles/modal";
import { useWindowDimensions } from "@app/statics/dimensionContext";
import useLocationHash from "@app/statics/hashHook";
import { useLocation } from "react-router-dom";

interface ICreditsProps {
  credits: Credit[];
  slidesToShow: number;
}

export const CreditsSlider = (props: ICreditsProps) => {
  //STATE
  const location = useLocation();
  const [currentCredit, setCurrentCredit] = React.useState<Credit>();
  const carousel = useRef<CarouselRef>(null);
  const { isMobile, WindowDimensionViewWidth } = useWindowDimensions();
  const [hash, setHash] = useLocationHash();

  //CREDITS CARD
  const Card = (credit: Credit) => {
    return (
      <div
        css={styles.card(credit.imageUrl)}
        onClick={() => setCurrentCredit(credit)}
      >
        <img src="./images/slide_push.png" alt={credit.title} />
        <div></div>
      </div>
    );
  };

  //CURRENT CREDIT HASH
  useEffect(() => {
    if (props.credits.length !== 0) {
      setHash(currentCredit ? `#${currentCredit?.slug}` : "");
    }
    // eslint-disable-next-line
  }, [currentCredit, props.credits]);
  useEffect(() => {
    if (props.credits.length !== 0 && hash) {
      setCurrentCredit(
        props.credits.find((c) => c.slug === hash.replace("#", ""))
      );
    }
    // eslint-disable-next-line
  }, [props.credits]);
  useEffect(() => {
    if (hash && hash.length > 0 && currentCredit && currentCredit.title) {
      (window as any).dataLayer &&
        (window as any).dataLayer.push({
          event: "VirtualPageview",
          virtualPageURL: location.pathname + "/" + hash.replace("#", ""),
        });
      (window as any).gtag &&
        (window as any).gtag("event", "credit-detail", {
          credit_title: currentCredit.title,
        });
    }
    // eslint-disable-next-line
  }, [hash]);

  //RENDER
  return (
    <>
      <section css={styles.wrapper}>
        <div css={styles.navigate} onClick={() => carousel.current?.prev()}>
          <LeftOutlined />
        </div>
        <div css={styles.carousel}>
          <Carousel
            slidesToShow={isMobile ? 1 : props.slidesToShow}
            swipe
            dots={false}
            ref={carousel}
            infinite
            autoplay
            autoplaySpeed={3000}
            waitForAnimate
            cssEase="ease-in-out"
          >
            {props.credits.map((credit, index) => (
              <Card {...credit} key={`credit_${index}`} />
            ))}
          </Carousel>
        </div>
        <div css={styles.navigate} onClick={() => carousel.current?.next()}>
          <RightOutlined />
        </div>
      </section>
      <Modal
        open={currentCredit !== undefined}
        width={isMobile ? "100vw" : WindowDimensionViewWidth(85)}
        closable
        onCancel={() => setCurrentCredit(undefined)}
        footer={null}
        css={modalStyles(isMobile)}
        destroyOnClose
      >
        <CreditDetail
          credit={currentCredit}
          standalone
          onClose={() => setCurrentCredit(undefined)}
          minHeight={600}
        />
      </Modal>
    </>
  );
};

export default CreditsSlider;
