import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    position: static;
    top: -999rem;
    left: -999rem;
    height: 0;
    width: 0;
    overflow: hidden;
  `,
};
