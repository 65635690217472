import { dimensions } from "@app/statics/dimensionContext/dimensions";
import { css } from "@emotion/core";

export const styles = {
  wrapper: (isMobile: boolean) => css`
    position: relative;
    display: flex;
    justify-content: center;
    padding: ${isMobile ? "0" : "0 1rem"};
    > section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    > div {
      z-index: 2;
      width: min(
        calc(100vw - ${isMobile ? "0px" : "2rem"}),
        calc(${dimensions.maxWidth}px - ${isMobile ? "0px" : "2rem"})
      );
    }
  `,
};
