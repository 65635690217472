import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    margin-top: 60px;
    background: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--color-anchor-dark);
    text-align: center;
    span {
      font-size: 0.75rem;
    }
    > * {
      margin: 0 0 0.65rem 0;
    }
  `,
};
