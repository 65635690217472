import { css } from "@emotion/core";

export const appStyles = {
  layout: css`
    min-height: 100vh;
    background-color: transparent;
  `,
  photoCredit: css`
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
    font-size: 12px;
    a {
      color: inherit;
    }
  `,
  mobileMenuWrapper: (showMenu: boolean, isMobile: boolean) => css`
    overflow: hidden;
    position: ${showMenu ? "fixed" : "inherit"};
    > section {
      position: fixed;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 1;
    }

    > div {
      transition: all 0.3s ease-in-out;
      width: ${isMobile ? "170vw" : "inherit"};
      display: flex;
      transform: translate(${showMenu ? "-70vw" : "0"}, 0);
      > * {
        flex: 1;
      }
    }
  `,
  mobileMenu: css``,
};
