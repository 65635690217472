import { css } from "@emotion/core";

export const styles = {
  wrapper: (isMobile: boolean) => css`
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10000000000000000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    > div {
      display: flex;
      flex-direction: column;
      ${isMobile ? "margin-top: -10vh;" : ""}
    }
    svg {
      width: 250px;
    }
  `,
};
