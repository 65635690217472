import { dimensions } from "@app/statics/dimensionContext/dimensions";
import { IHeaderDirection } from "@app/statics/routes/interfaces";
import { css } from "@emotion/core";

export const styles = {
  logo: css`
    width: 250px;
    display: block;
    margin: 0 2rem;
  `,
  wrapper: (isMobile: boolean) => css`
    margin: ${isMobile ? "3rem 0 0 0" : "3rem -1rem 0 -1rem"};
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    display: flex;
    padding: 0;
  `,
  headerWrapper: (direction: IHeaderDirection) => css`
    flex: 1;
    display: flex;
    justify-content: ${direction};
    > * {
      margin: 0 1rem;
      font-size: 1rem;
      a {
        color: var(--color-anchor-dark);
      }
    }
    h1 {
      font-size: 1rem;
      color: (--var-color-non-anchor);
      pointer-events: none;
      margin: 0px 1rem;
    }
    @media (max-width: ${dimensions.maxWidth}px) {
      ${direction === "left" && "display:none;"}
      ${direction === "center" && "flex:initial;"}
      ${direction === "right" && "margin-right: 2rem;"}
    }
  `,
};
