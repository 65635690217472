import React from "react";
import { Layout } from "antd";
import { styles } from "./styles";
import { Typography } from "../typography";
import { useWindowDimensions } from "@app/statics/dimensionContext";

//EXTRACTS
const { Footer } = Layout;

//RENDER
export const SiteFooter = () => {
  //CONTEXT
  const { isMobile } = useWindowDimensions();

  //RENDER
  return (
    <Footer css={styles.wrapper}>
      <Typography light>
        <>
          &copy; {new Date().getFullYear()} Songbird Perspective Inc.
          {isMobile ? <br /> : " - "}All rights reserved.
        </>
      </Typography>
      <Typography light>
        <>
          <span>
            Agave, Music & Media is a trademark of Songbird Perspective Inc.
          </span>
        </>
      </Typography>
    </Footer>
  );
};
