import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { isMobile as checkIsMobile } from "react-device-detect";
import { dimensions } from "./dimensions";
import { IWindowDimensionsContext } from "./interfaces";

const WindowDimensionsContext = createContext<IWindowDimensionsContext>({
  windowWidth: window.innerWidth,
  isMobile: window.innerWidth < dimensions.setToMobileWidth,
  WindowDimensionViewWidth: (vw: number) => "",
});

const useWindowDimensions = () => {
  const context = useContext(WindowDimensionsContext);
  if (!context) {
    throw new Error("useWindowSize must be used within a WindowSizeProvider");
  }
  return context;
};

const WindowDimensionProvider = ({ children }: { children: ReactNode }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = checkIsMobile || windowWidth < dimensions.setToMobileWidth;

  const WindowDimensionViewWidth = (vw: number) => {
    return `min(calc(${vw}vw - ${isMobile ? "0px" : "2rem"}), calc(${
      (dimensions.maxWidth / 100) * vw
    }px - ${isMobile ? "0px" : "2rem"}))`;
  };

  return (
    <WindowDimensionsContext.Provider
      value={{ windowWidth, isMobile, WindowDimensionViewWidth }}
    >
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export { useWindowDimensions, WindowDimensionProvider };
