import { css } from "@emotion/core";

export const modalStyles = (isMobile: boolean) =>
  isMobile
    ? css`
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0;
        border-radius: 0;
        .ant-modal-content {
          height: 100vh;
          width: 100vw;
          margin: 0;
          top: 0;
          border-radius: 0;
        }
      `
    : css``;
