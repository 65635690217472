import { css } from "@emotion/core";

export const styles = {
  wrapper: (isMobile: boolean) => css`
    flex: 1;
    display: flex;
    margin: 0 7vw;
    padding: ${isMobile ? "85px 1rem 1rem 1rem" : "85px 0 0 0"};
    flex-direction: column;
  `,
  awardCategory: (isMobile: boolean) => css`
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 1rem;
    margin: -1rem -1rem 4rem -1rem;

    h1 {
      ${isMobile ? "font-size: 1.5rem;" : "inherit"}
    }
  `,
  awardWrapper: css`
    padding: 0.5rem 0.5rem 0 0.5rem;
    display: flex;
    margin-bottom: 0.5rem;
    img {
      width: 60px;
      margin-right: 1rem;
      cursor: pointer;
      transition: all ease 0.3s;
      :hover {
        transform: scale(1.05);
      }
    }
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    h4 {
      margin-bottom: 0.25rem;
    }
    h5 {
      margin: 0;
      font-weight: 400;
    }
  `,
};
