import { IElementType } from "@app/components/typography/interfaces";

export const headerDirections = ["left", "center", "right", "hidden"] as const;
export type IHeaderDirection = (typeof headerDirections)[number];

export interface IRoute {
  primaryPage: boolean;
  path?: string;
  label: string;
  element?: any;
  logo: boolean;
  direction: IHeaderDirection;
  type?: IElementType;
  backgroundCss?: any;
  imageCredit?: any;
  blankPage?: boolean;
}
