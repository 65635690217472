import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import emailjs from "emailjs-com";

export const EmailJSForm = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSubmit = (values: {
    name: string;
    email: string;
    message: string;
  }) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        values,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string
      )
      .then(
        (result) => {
          form.resetFields(); // Clear the form fields
          setIsModalVisible(true); // Show the modal dialog
        },
        (error) => {
          console.error("Error sending email:", error.text);
        }
      );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Form
        form={form}
        name="contact_form"
        onFinish={handleSubmit}
        layout="vertical"
        style={{ maxWidth: 400, margin: "auto" }}
      >
        <Form.Item
          label="Name:"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Company:"
          name="company"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address:"
          name="email"
          rules={[
            { required: true, message: "Please input your company name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Message:"
          name="message"
          rules={[{ required: true, message: "Please input your message!" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Thank You"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <p>Thank you for contacting us. We will get back to you soon.</p>
      </Modal>
    </>
  );
};
