import React from "react";
import { styles } from "./styles";
import { useWindowDimensions } from "@app/statics/dimensionContext";
import { IRoute } from "@app/statics/routes/interfaces";

interface IWindowDimensionsProps {
  styles?: IRoute[];
  currentPath: string;
  children?: React.ReactNode;
}

export const WindowDimensions = (props: IWindowDimensionsProps) => {
  const { isMobile } = useWindowDimensions();

  //RENDER
  return (
    <section css={[styles.wrapper(isMobile)]}>
      {props.styles?.map((route, index) => (
        <section
          key={`route_bg_${index}`}
          css={[
            route.backgroundCss,
            {
              opacity: route.path === props.currentPath ? 1 : 0,
              zIndex: route.path === props.currentPath ? 1 : 0,
            },
          ]}
        ></section>
      ))}

      <div>{props.children}</div>
    </section>
  );
};

export default WindowDimensions;
