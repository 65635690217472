import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { styles } from "./styles";
import { Link, useLocation } from "react-router-dom";
import { IRoute, headerDirections } from "@app/statics/routes/interfaces";
import routes from "@app/statics/routes";
import { Typography } from "../typography";
import { ReactComponent as Logo } from "@app/statics/svgs/logo.svg";
import { useWindowDimensions } from "@app/statics/dimensionContext";

//EXTRACTS
const { Header } = Layout;

//RENDER
export const SiteHeader = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  //RENDER LINK
  const renderLink = (route: IRoute, key: string) => {
    if (route.logo) {
      return (
        <Link to={route.path ?? "/"} type={route.type} key={key}>
          <Logo css={styles.logo} />
        </Link>
      );
    } else {
      return (
        <Typography
          weight={currentPath === route.path ? "400" : "200"}
          type={route.type}
          key={key}
        >
          {route.path ? (
            <Link to={route.path ?? "/"}>{route.label}</Link>
          ) : (
            <>{route.label}</>
          )}
        </Typography>
      );
    }
  };

  return (
    <Header css={styles.wrapper(isMobile)}>
      {headerDirections
        .filter((m) => m === (isMobile ? "center" : m))
        .filter((m) => m !== "hidden")
        .map((direction) => (
          <div
            css={styles.headerWrapper(direction)}
            key={`direction_${direction}`}
          >
            {routes
              .filter((route) => route.direction === direction)
              .map((route, index) =>
                renderLink(route, `entry_${direction}_${index}`)
              )}
          </div>
        ))}
    </Header>
  );
};
