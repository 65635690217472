import { css } from "@emotion/core";

export const styles = {
  wrapper: (isMobile: boolean) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${isMobile ? "85px 1.5rem 1.5rem 1.5rem" : "85px 0 0 0"};
  `,
  credits: (isMobile: boolean) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${isMobile ? "1.5rem" : "1rem"};
  `,
  credit: (width: string, isMobile: boolean) => css`
    cursor: pointer;
    flex: 1 0 calc(${width} - ${isMobile ? "3rem" : "1rem"});
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
    filter: drop-shadow(3px 3px 6px #d8d8d8);
    transition: all 0.3s ease-in-out;
    :hover {
      transform: scale(1.03);
    }
  `,
};
