import React from "react";
import { styles } from "./styles";
import { Typography } from "@app/components/typography";
import { Button, Space } from "antd";
import { ReactComponent as IMDB } from "@app/statics/svgs/imdb.svg";
import { FilePdfOutlined } from "@ant-design/icons";
import { useContentContext } from "@app/statics/contentContext";
import { TeamMember } from "@app/statics/contentContext/contentTypes";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useWindowDimensions } from "@app/statics/dimensionContext";

export const About = () => {
  //CONTEXT
  const content = useContentContext();
  const { isMobile } = useWindowDimensions();

  //GET PRIMARY
  const primary =
    content.contentState.team.find((member) => member.isPrimary) ??
    ({} as TeamMember);

  //RENDER
  return (
    <section css={styles.wrapper(isMobile)}>
      <section>
        <div css={styles.picture(`https:${primary?.photo?.fields?.file?.url}`)}>
          <img
            src={"./images/img_push.png"}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div css={styles.pictureInfo}>
          <Typography type="h6">
            <div
              dangerouslySetInnerHTML={{
                __html: primary?.photo?.fields.description ?? "",
              }}
            ></div>
          </Typography>
        </div>
        <Space direction="vertical">
          {primary.fullCreditsPdf && (
            <Button
              type="primary"
              icon={<FilePdfOutlined />}
              href={`https:${primary?.fullCreditsPdf?.fields?.file?.url}`}
              target="_blank"
              onClick={() => {
                (window as any).gtag &&
                  (window as any).gtag("event", "credits-download-pdf", {});
              }}
            >
              Credits Download (PDF)
            </Button>
          )}
          {primary?.imdbLink && (
            <a
              href={primary?.imdbLink}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                (window as any).gtag &&
                  (window as any).gtag("event", "imdb-link-visit", {});
              }}
            >
              <IMDB css={{ height: "32px", marginTop: "4px" }} />
            </a>
          )}
        </Space>
      </section>
      <section css={styles.profile}>
        <Typography type="h1">
          <>{primary.fullName}</>
        </Typography>
        <Typography type="h2">
          <>{primary.title}</>
        </Typography>
        <Typography>
          <>{documentToReactComponents(primary.biography)}</>
        </Typography>
      </section>
    </section>
  );
};

export default About;
