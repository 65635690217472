import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 85px;
  `,
  credits: (width: string) => css`
    display: flex;
    justify-content: center;
    > div {
      width: ${width};
    }
  `,
};
