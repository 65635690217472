import React from "react";
import { styles } from "./styles";
import CreditsSlider from "@app/components/creditsSlider";
import { useContentContext } from "@app/statics/contentContext";
import { useWindowDimensions } from "@app/statics/dimensionContext";

export const Landing = () => {
  //CONTEXT
  const content = useContentContext();
  const { WindowDimensionViewWidth } = useWindowDimensions();

  //RENDER
  return (
    <section css={styles.wrapper}>
      <section css={styles.credits(WindowDimensionViewWidth(100))}>
        <div>
          <CreditsSlider
            slidesToShow={3}
            credits={content.contentState.landingCredits}
          />
        </div>
      </section>
    </section>
  );
};

export default Landing;
