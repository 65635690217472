import React, { Fragment, useState, useEffect } from "react";
import "./statics/styles/colors.css";
import { ConfigProvider, Layout } from "antd";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { SiteHeader } from "./components/siteHeader";
import { SiteFooter } from "./components/siteFooter";
import routes from "./statics/routes";
import { IRoute } from "./statics/routes/interfaces";
import Images from "./statics/images";
import WindowDimensions from "./components/windowDimensions";
import { useContentContext } from "./statics/contentContext";
import Splash from "./components/splash";
import { appStyles } from "./statics/styles/appStyles";
import Hamburger from "hamburger-react";
import { MobileMenu } from "./components/mobileMenu";
import { useWindowDimensions } from "./statics/dimensionContext";
import { Helmet } from "react-helmet";

const { Content } = Layout;

export const appStatics = {
  title: "Agave, Music & Media",
};

function App() {
  //STATES
  const location = useLocation();
  const content = useContentContext();
  const [currentRoute, setCurrentRoute] = useState<IRoute | undefined>(
    routes.find((route) => route.path === location.pathname)
  );
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { isMobile } = useWindowDimensions();

  //GA
  useEffect(() => {
    (window as any).dataLayer &&
      (window as any).dataLayer.push({
        event: "VirtualPageview",
        virtualPageURL: location.pathname + location.search,
      });
  }, [location]);

  //ROUTING
  useEffect(() => {
    setCurrentRoute(routes.find((route) => route.path === location.pathname));
  }, [location]);

  //INIT DATA LOAD
  useEffect(() => {
    if (content.contentState.loading) content.init();
    window.scrollTo(0, 1);
    // eslint-disable-next-line
  }, []);

  //SCROLL TO TOP
  useEffect(() => {
    if (showMobileMenu) window.scrollTo(0, 1);
  }, [showMobileMenu]);

  //RENDER
  return (
    <Fragment>
      <Helmet>
        <title>
          {appStatics.title}
          {currentRoute?.label && currentRoute.label.length > 0
            ? ` - ${currentRoute.label}`
            : ""}
        </title>
        <link
          rel="canonical"
          href={`https://agavemusicmedia.com${currentRoute?.path}`}
        />
      </Helmet>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "var(--color-button-primary)",
              colorPrimaryActive: "var(--color-button-primary)",
              colorPrimaryHover: "var(--color-button-primary-hover)",
              colorPrimaryBorder: "var(--color-button-primary)",
              colorPrimaryBorderHover: "var(--color-button-primary-hover)",
            },
          },
        }}
      >
        {currentRoute?.blankPage !== true && <Splash />}
        <section css={appStyles.mobileMenuWrapper(showMobileMenu, isMobile)}>
          {isMobile && (
            <section>
              <Hamburger
                toggled={showMobileMenu}
                onToggle={(toggled) => setShowMobileMenu(toggled)}
                size={20}
              />
            </section>
          )}
          <div>
            <WindowDimensions
              styles={routes.filter((m) => m.backgroundCss !== undefined)}
              currentPath={currentRoute?.path || "/"}
            >
              <Layout css={[appStyles.layout]}>
                {currentRoute?.blankPage !== true && <SiteHeader />}
                <Content style={{ display: "flex" }}>
                  <Routes>
                    {routes.map((route, index) => (
                      <Route
                        path={route.path}
                        element={route.element}
                        key={`route_${index}`}
                      />
                    ))}
                    <Route
                      path="*"
                      element={
                        location.pathname.startsWith("/projects") ? (
                          <Navigate
                            to={`/projects#${location.pathname.replace(
                              "/projects/",
                              ""
                            )}`}
                          />
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                  </Routes>
                </Content>
                {currentRoute?.blankPage !== true && <SiteFooter />}
              </Layout>
              {!isMobile && currentRoute?.imageCredit && (
                <div css={appStyles.photoCredit}>
                  {currentRoute?.imageCredit}
                </div>
              )}
            </WindowDimensions>

            {isMobile && (
              <MobileMenu onNavigate={() => setShowMobileMenu(false)} />
            )}
          </div>
        </section>
        <Images />
      </ConfigProvider>
    </Fragment>
  );
}

export default App;
