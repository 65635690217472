import React, { ReactElement, ReactNode, useReducer } from "react";
import ContentContext, { initialContentState } from ".";
import { IContentState } from "./interfaces";
import { createClient } from "contentful";
import {
  SortedCreditsSkeleton,
  CreditSkeleton,
  AwardSkeleton,
  Award,
  TypeTeamMemberSkeleton,
  TeamMember,
} from "./contentTypes";

const reducer = (state: IContentState, newState: IContentState) => {
  return {
    ...state,
    ...newState,
  };
};

const getSlug = (title: string) => {
  return title
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, "")
    .replace(/\s+/g, "-");
};

const ContentProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [contentState, dispatch] = useReducer(reducer, initialContentState);
  const contentfulClient = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? "",
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? "",
  });

  const setState = (newState: IContentState) => {
    //RETURN
    dispatch(newState);
  };

  const init = () => {
    Promise.all([
      contentfulClient.getEntries<SortedCreditsSkeleton>({
        content_type: "sortedCredits",
      }),
      contentfulClient.getEntries<AwardSkeleton>({ content_type: "award" }),
      contentfulClient.getEntries<TypeTeamMemberSkeleton>({
        content_type: "teamMember",
      }),
    ]).then(([creditsEntries, awardsEntries, teamMembers]) => {
      setState({
        ...contentState,
        loading: false,
        landingCredits:
          creditsEntries.items
            .find((m) => m.fields.key === "credits-landing")
            ?.fields.credits.map((value: unknown) => {
              return {
                ...(value as CreditSkeleton)?.fields,
                slug: getSlug((value as CreditSkeleton)?.fields.title),
              };
            }) ?? [],
        allCredits:
          creditsEntries.items
            .find((m) => m.fields.key === "credits-all")
            ?.fields.credits.map((value: unknown) => {
              return {
                ...(value as CreditSkeleton)?.fields,
                slug: getSlug((value as CreditSkeleton)?.fields.title),
              };
            }) ?? [],
        awards: awardsEntries.items.map(
          (value) => value.fields as unknown as Award
        ),
        team: teamMembers.items.map(
          (value) => value.fields as unknown as TeamMember
        ),
      });
    });
  };

  return (
    <ContentContext.Provider
      value={{
        contentState: contentState,
        init,
        setState,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
export default ContentProvider;
