import { css } from "@emotion/core";

export const styles = {
  wrapper: (fontWeight: number, center: boolean) => css`
    font-family: "Libre Franklin", sans-serif !important;
    font-weight: ${fontWeight};
    color: var(--color-dark-gray);
    ${center ? "text-align: center;" : ""};
  `,
  h1: css`
    font-size: 1.75rem;
    margin: 0 0 0.45rem 0;
    font-weight: 400;
  `,
  h2: css`
    font-size: 1.15rem;
    margin: 0 0 -0.75rem 0;
  `,
};
