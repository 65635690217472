import React from "react";
import { styles } from "./styles";
import { preloadImages } from "./preloadImages";

export const Images = () => {
  return (
    <section css={styles.wrapper}>
      {preloadImages.map((image) => (
        <img alt="" src={image} key={image} />
      ))}
    </section>
  );
};

export default Images;
