import type { EntryFieldTypes, EntrySkeletonType, Asset } from "contentful";
import { Document as RichTextDocument } from "@contentful/rich-text-types";

export interface Credit {
  slug: string;
  title: string;
  subtitle?: EntryFieldTypes.Text;
  category: "Advertisement" | "Movie" | "TV Show";
  year: number;
  endYear: number;
  imageUrl: string;
  spotifyId?: string;
  spotifyIdCategory?: "Album" | "Playlist" | "Track";
  imdbLink?: string;
  tunefindLink?: string;
  youtubeId?: string;
  soundCloudId?: string;
}
export type CreditSkeleton = EntrySkeletonType<Credit, "credit">;

export interface SortedCredits {
  key: EntryFieldTypes.Symbol<"credits-all" | "credits-landing">;
  credits: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<CreditSkeleton>>;
}
export type SortedCreditsSkeleton = EntrySkeletonType<
  SortedCredits,
  "sortedCredits"
>;
export const AwardCategories = [
  "Primetime Emmy Awards",
  "Guild of Music Supervisors Awards",
  "Imagen Foundation Awards",
];
type AwardCategory = (typeof AwardCategories)[number];
export interface Award {
  award: AwardCategory;
  year?: Number;
  awardCategory: string;
  outcome: "Nominee" | "Winner";
  linkedCredit?: EntryFieldTypes.EntryLink<CreditSkeleton>;
}
export type AwardSkeleton = EntrySkeletonType<Award, "award">;

export interface TeamMember {
  fullName: string;
  title: string;
  isPrimary: boolean;
  biography: RichTextDocument;
  photo: Asset;
  fullCreditsPdf?: Asset;
  imdbLink?: string;
}

export type TypeTeamMemberSkeleton = EntrySkeletonType<
  TeamMember,
  "teamMember"
>;
