import React from "react";
import { styles } from "./styles";
import { Typography } from "@app/components/typography";
import { useContentContext } from "@app/statics/contentContext";
import {
  Award,
  AwardCategories,
  Credit,
  CreditSkeleton,
} from "@app/statics/contentContext/contentTypes";
import { Modal } from "antd";
import CreditDetail from "@app/components/creditDetail";
import { modalStyles } from "@app/statics/styles/modal";
import { useWindowDimensions } from "@app/statics/dimensionContext";

export const Awards = () => {
  //STATE
  const [currentCredit, setCurrentCredit] = React.useState<Credit>();
  const { isMobile, WindowDimensionViewWidth } = useWindowDimensions();

  //CONTEXT
  const content = useContentContext();

  //RENDER AWARD
  const RenderAward = (award: Award, category: string, index: number) => {
    const credit: Credit = (award.linkedCredit as unknown as CreditSkeleton)
      .fields;
    return (
      <div css={styles.awardWrapper} key={`award_${category}_${index}`}>
        <img
          src={credit.imageUrl}
          alt={credit.title}
          onClick={() => setCurrentCredit(credit)}
        />
        <div>
          <Typography type="h2">
            <>{`${award.year} ${award.outcome}`}</>
          </Typography>
          <Typography type="h4">
            <>{award.awardCategory}</>
          </Typography>
          <Typography type="h5">
            <>{credit.title}</>
          </Typography>
        </div>
      </div>
    );
  };

  //RENDER CATEGORY
  const RenderCategory = (category: string) => {
    return (
      <section css={styles.awardCategory(isMobile)} key={`awards_${category}`}>
        <Typography type="h1">
          <>{category}</>
        </Typography>
        {content.contentState.awards
          .filter((m) => m.award === category)
          .map((award, index) => {
            return RenderAward(award, category, index);
          })}
      </section>
    );
  };

  //RENDER
  return (
    <>
      <section css={styles.wrapper(isMobile)}>
        {AwardCategories.map((category) => RenderCategory(category))}
      </section>
      <Modal
        open={currentCredit !== undefined}
        width={WindowDimensionViewWidth(85)}
        closable
        onCancel={() => setCurrentCredit(undefined)}
        footer={null}
        css={modalStyles(isMobile)}
        destroyOnClose
      >
        <CreditDetail
          credit={currentCredit}
          standalone
          onClose={() => setCurrentCredit(undefined)}
          minHeight={600}
        />
      </Modal>
    </>
  );
};

export default Awards;
