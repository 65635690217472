import React from "react";
import { styles } from "./styles";

//PROPS
interface IEmailProps {
  emailAddress: string;
}

//RENDER
const Email = (props: IEmailProps) => {
  //RENDER
  return (
    <div
      css={styles.wrapper}
      onClick={() => window.open(`mailto:${props.emailAddress}`)}
    >
      <span>
        {props.emailAddress
          .split("@")[0]
          .split("")
          .map((letter, index) => (
            <i key={`email_f_${index}`}>{letter}</i>
          ))}
      </span>
      <span>
        <svg height="14" width="14">
          <text x="1" y="12">
            @
          </text>
        </svg>
      </span>
      <span>
        {props.emailAddress
          .split("@")[1]
          .split("")
          .map((letter, index) => (
            <i key={`email_b_${index}`}>{letter}</i>
          ))}
      </span>
    </div>
  );
};

export default Email;
