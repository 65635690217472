import { css } from "@emotion/core";

export const styles = {
  wrapper: css`
    display: flex;
    position: relative;
  `,
  card: (backgroundImageUrl: string) => css`
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    > div {
      flex: 1;
      width: 90%;
      height: 100%;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 5%;
      background: url(${backgroundImageUrl}) no-repeat center center;
      background-size: cover;
      transition: all ease 0.3s;
      cursor: pointer;
      :hover {
        transform: scale(1.02);
      }
    }
    > img {
      display: block;
      padding: 0 5%;
      width: 100%;
    }
  `,
  carousel: css`
    width: calc(100% - 100px);
  `,
  navigate: css`
    cursor: pointer;
    transition: all ease 0.3s;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: var(--color-light-gray);
    :hover {
      transform: scale(1.2);
    }
  `,
  infoDialog: css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(var(--main-bg-color-val), 0.8);
    z-index: 100;
    display: flex;

    .__sub {
      display: flex;
      h4 {
        flex: 1;
      }
      margin-bottom: 1rem;
      align-items: center;
    }

    iframe {
      border: none;
      flex: 1;
    }
    h2 {
      font-weight: 600;
      margin-bottom: -0.5rem;
    }
    h4 {
      font-weight: 400;
      padding-right: 2rem;
    }
    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 1rem 2rem 2rem 2rem;
      background: rgba(255, 255, 255, 0.9);
      flex: 1;

      .__close {
        transition: all ease 0.3s;
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 30px;
        color: var(--color-light-gray);
        cursor: pointer;
        :hover {
          transform: scale(1.2);
        }
      }
    }
  `,
};
