import { css } from "@emotion/core";

export const styles = {
  wrapper: (width: string) => css`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 85px;

    h1 {
      margin: 0 0 2rem 0;
    }
    h2 {
      margin: 0 0 1rem 0;
    }

    > section {
      margin-bottom: 2rem;
      width: ${width};
      padding-bottom: 3rem;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      :last-of-type {
        border-bottom: none;
        margin-bottom: -3rem;
      }
    }
    > div {
      overflow: hidden;
      > div {
        width: ${width};
      }
    }
    h3 {
      margin-bottom: 0.5rem;
    }

    .__split {
      display: flex;
      margin-top: -0.5rem;
      > div {
        margin-right: 1rem;
        > div:last-of-type {
          margin-top: 0.5rem;
        }
      }
    }
  `,
  form: css`
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 1.5rem;
    border-radius: 5px;
  `,
  mobile320: css`
    .hide-320 {
      display: initial;
    }
    .show-320 {
      display: none;
    }
    @media (max-width: 320px) {
      .hide-320 {
        display: none;
      }
      .show-320 {
        display: initial;
      }
    }
  `,
};
