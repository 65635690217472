import { useEffect, useState } from "react";

const useLocationHash = () => {
  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    // Function to handle hash change events
    const handleHashChange = () => {
      setHash(window.location.hash);
    };

    // Add an event listener for hashchange
    window.addEventListener("hashchange", handleHashChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Function to set the hash
  const setNewHash = (newHash: string): void => {
    window.history.replaceState(
      {},
      document.title,
      window.location.href.split("#")[0] + newHash
    );

    setHash(newHash);
  };

  return [hash, setNewHash] as const;
};

export default useLocationHash;
